import axios from "axios";
import { Constants } from '../utils/Constants';
 
export function create(form) {
	return axios.post(`${Constants.baseUrl}/agendamentos`, form);
}

export function check(form) {
	return axios.post(`${Constants.baseUrl}/agendamentos/check`, form);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/agendamentos/${id}`);
}

export function getSimpleListDiarioByFilial(id) {
	return axios.get(`${Constants.baseUrl}/agendamentos/simpleListDiarioByFilial/${id}`);
}

export function update(id, data) {
	return axios.put(`${Constants.baseUrl}/agendamentos/${id}`, data);
}

export function resizeUpdate(id, data) {
	return axios.put(`${Constants.baseUrl}/agendamentos/resize/${id}`, data);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/agendamentos/${id}`);
}

export function finalizar(id) {
	return axios.put(`${Constants.baseUrl}/agendamentos/finalizar/${id}`);
}

export function changeClienteAgendamentoStatus(id, data) {
	return axios.put(`${Constants.baseUrl}/agendamentos/changeclientestatus/${id}`, data);
}

export function getAgendamentos(data) {
	return axios.post(`${Constants.baseUrl}/agendamentos/list`, data);
}

export function cancelIntervalo(data) {
	return axios.post(`${Constants.baseUrl}/agendamentos/cancelIntervalo`, data);
}

export function cancelFolga(id) {
	return axios.delete(`${Constants.baseUrl}/agendamentos/cancelFolga/${id}`);
}

export function storeAgendamentoConsumo(data) {
	return axios.post(`${Constants.baseUrl}/agendamentos/storeAgendamentoConsumo`, data);
}

export function getFilaExibicao() {
	return axios.get(`${Constants.baseUrl}/agendamentos/fila-exibicao`);
}