import React, { Component } from "react";

export default class BetterButton extends Component {
	render() {
		return (
			<>
				<button className={`btn ${this.props.className ? this.props.className : ''} ${this.props.outline ? "better-outline-btn better-outline-btn-" + this.props.colorType : "better-btn better-btn-" + this.props.colorType } ` }
				onClick={this.props.onClick}
				style={this.props.style} disabled={this.props.disabled} type={this.props.type}>
					{this.props.icon ?
						<i className={`${this.props.icon}`}></i>
						: null
					}
					{this.props.text}
				</button>
			</>
		);
	}
}