import React, { Component } from 'react';
import BetterBox from './BetterBox';

export default class BetterBoxRedesign extends Component {
	render() {
		return (
			<div className='better-box-redesign' >
				<BetterBox title={this.props.title} subtitle={this.props.subtitle} actions={this.props.actions} >
					{this.props.children}
				</BetterBox>
			</div>
		)
	}
}