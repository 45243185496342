import { Col, Row } from "react-bootstrap";
import BetterBoxRedesign from "../../components/BetterBoxRedesign";
import BetterButton from "../../components/BetterButton";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { getFilaExibicao } from "../../crud/agendamento.crud";
import Swal from "sweetalert2";
import moment from "moment";
import 'moment/locale/pt-br';
moment.locale('pt-br')

export default function FilaExibicaoAgendamento() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		setLoading(true);

		fetchData();

		setInterval(() => {
			fetchData();
		}, 300000)

		setLoading(false);
	}, []);

	const fetchData = async () => {
		await getFilaExibicao().then(res => {
			if (res.status == 200) {

				setData(currentEvents =>
					currentEvents.map(currentEvent => {
						if (!res.data.find(event => event.id === currentEvent.id)) {
							return { ...currentEvent, fade: 'fade-out' }
						}

						return currentEvent;
					})
				);
			}

			setTimeout(() => {
				setData(defineWheterAtendimentoIsHappening(res.data));
			}, 4000);
		}).catch(error => {
			Swal.fire('Ops', error.response.data.errors ? error.response.data.errors[0] : "Houve um erro ao buscar os agendamentos", 'error');
			return
		});
	};

	const defineWheterAtendimentoIsHappening = (events) => {
		events.forEach(event => {
			if (moment(event.age_inicio).isBefore(moment()) && moment(event.age_fim).isAfter(moment()) && event.age_cliente_status == 'Chegou') {
				event.acontecendo = true;
			}
		});

		return events;
	};

	const getTitle = (event) => {
		if (event.acontecendo) {
			return <span><i className={`margin-icon fa fa-star`}></i>{event.cli_name}</span>
		}

		return event.cli_name;
	};

	return (
		<BetterBoxRedesign customKTPortletClassName="better-box-custom-agendamento" >
			<Row>
				<Col lg={12} md={12} xs={12} >
					{data.length > 0 ?
						<>
							<div className="fila-exibicao-agendamentos-day-container">
								{window.innerWidth >= 962 ?
									<span className="fila-exibicao-agendamentos-day-text">
										{`${moment().format('dddd').charAt(0).toUpperCase()}${moment().format('dddd').slice(1)}, ${moment().format('D')} ${moment().format('MMM').charAt(0).toUpperCase()}${moment().format('MMM').slice(1)}. ${moment().format('YYYY')}`}
									</span>
									: null
								}
							</div>

							<div className="d-flex justify-content-center m-5">
								<div className="fila-exibicao-agendamentos-legenda">
									<i className={`margin-icon fa fa-star`}></i>
									<span>Atendimento em andamento</span>
								</div>
								<div className="fila-exibicao-agendamentos-legenda">
									<div className="fila-exibicao-agendamentos-legenda-circulo fila-exibicao-agendamentos-chegou"></div>
									<span>Atendimento confirmado</span>
								</div>
								<div className="fila-exibicao-agendamentos-legenda">
									<div className="fila-exibicao-agendamentos-legenda-circulo fila-exibicao-agendamentos-aguardando"></div>
									<span>Aguardando chegada</span>
								</div>
							</div>

							<div className="events-calendar-profissional" >
								{data.map((event, i) => {
									return (
										<div className={`event-calendar-profissional fila-exibicao-agendamentos-${event?.age_cliente_status.toLowerCase()} ${event.fade}`}>
											<div className="event-calendar-details">
												<div className="event-titulo">
													{getTitle(event)}
												</div>
												<div className="event-horario">{`${moment(event.age_inicio).format('HH:mm')} - ${moment(event.age_fim).format('HH:mm')}`}</div>
											</div>
										</div>
									)
								})}
							</div>
						</>
						:
						<div className="col-lg-12">
							<div className="kt-portlet__body">
								<div className="kt-widget4">
									<div className="kt-widget4__item">
										<div className="kt-widget4__info">
											<p className="kt-widget4__username" style={{ marginBottom: 0, textAlign: "center", fontSize: "24px" }}>Não há agendamentos futuros para hoje.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</Col>
			</Row>

		</BetterBoxRedesign>
	)
}